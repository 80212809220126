<template>
  <div>
    <h2>介紹人分潤報表</h2>

    <div class="text-end">
      <a
        target="_blank"
        class="mb-2 d-block"
        href="https://lookerstudio.google.com/embed/reporting/f2c8aa67-1cea-4108-a274-f07860674464/page/p_e8hk8zpi4c"
      >
        另開報表
      </a>
    </div>
    <div class="col-12">
      <!-- 16x9 -->
      <div class="ratio ratio-16x9 text-center mt-4 mb-4">
        <iframe
          class="embed-responsive-item"
          src="https://lookerstudio.google.com/embed/reporting/f2c8aa67-1cea-4108-a274-f07860674464/page/p_e8hk8zpi4c"
          style="max-width: 100%; height: 100%"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</template>
